import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import { auth0Config } from "../constants";
import { getUserInfoForAuth0Id } from "../services/api";

const defaultContextObject = {
  isAuthenticated: null,
  loading: true,
  loginWithRedirect: null,
  logout: null,
  user: null,
};

export const AuthContext = React.createContext(defaultContextObject);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0Client] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initialise = async () => {
      const client = await createAuth0Client(auth0Config);
      setAuth0Client(client);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await client.handleRedirectCallback();
        if (appState && appState.targetUrl) {
          navigate(appState.targetUrl);
        }
      }
      const isAuthenticated = await client.isAuthenticated();

      if (isAuthenticated) {
        const auth0User = await client.getUser();
        const response = await getUserInfoForAuth0Id(auth0User.sub);
        if (response.user) {
          setIsAuthenticated(true);
          setUser(response.user);
        } else {
          setIsAuthenticated(false);
        }
      }

      setLoading(false);
    };

    initialise();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

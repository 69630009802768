import Frisbee from "frisbee";

const client = new Frisbee({
  baseURI: `/.netlify/functions`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getUserInfoForAuth0Id = async (auth0Id) => {
  const response = await client.post("/get-user-info", { body: { auth0Id } });

  if (response.ok) {
    return { user: response.body };
  }
  return { error: true };
};

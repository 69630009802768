export const auth0Audience = process.env.GATSBY_AUTH0_AUDIENCE;
export const auth0ClientId = process.env.GATSBY_AUTH0_CLIENT_ID;
export const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN;
export const auth0Scope = process.env.GATSBY_AUTH0_SCOPE;
export const apiBaseUrl = process.env.GATSBY_API_BASE_URL;
export const auth0RedirectUri =
  typeof window !== `undefined`
    ? `${window.location.protocol}//${window.location.host}/gateway`
    : null;
// auth0Namespace is used in auth0 Rules and Hooks to add fields to the idToken
// e.g. the user object we get back after registration or login will contain fields like [`${auth0Namespace}/customField`]: "custom value"
export const auth0Namespace = "https://wvs-academy";
export const auth0UseRefreshTokens = true;
export const auth0CacheLocation = "localstorage";

export const auth0Config = {
  audience: auth0Audience,
  clientId: auth0ClientId,
  domain: auth0Domain,
  authorizationParams: {
    redirect_uri: auth0RedirectUri,
  },
  useRefreshTokens: auth0UseRefreshTokens,
  cacheLocation: auth0CacheLocation,
  scope: auth0Scope,
};

export const ResourceTypes = {
  CASE_REPORTS: "case-reports",
  HOME: "home",
  LEARN: "learn",
  QUICK_REFERENCE: "quick-reference",
  RESEARCH_SPOTLIGHT: "research-spotlight",
  VIDEOS: "videos",
};

export const Access = {
  PUBLIC: "public",
  LOGGED_IN: "logged-in",
};

export const cookieNames = {
  cookieControl: "cookie-control",
};
